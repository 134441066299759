import * as actionTypes from '../actions/actionTypes';
import { updateObject, mergeObjects } from '../../utility/utility';

const intialState = {
    lanesListLoading: true,
    lanesListError: false,
    lanesList: [],
    lanesListMeta: {},
    laneFilterValues:{
        transportModes :  [],
        originDestinations:   [],
        productNames: []
    },
    laneDetails: {stability: [], routes:[]},
    routeDetails: {},
    legStability: [],
    routeInsights: {},
    legInsights: {},
    insightsMapping: {},
    eventListData: [],
    laneEventListData: [],
    laneHighEventListData: [],
    laneLowEventListData: [],
    laneMediumEventListData: [],
    eventCounts:null,
    allEventCounts: null,
    isEventListLoad: false,
    eventMetaTotalCount: {},
    laneEventMetaTotalCount: 0,
    isEventDetailLoad: false,
    eventDetailData: [],
    severityLevel: "all",
    eventTypeValue : "",
    isNewPerformanceLoad: false,
    athenaInsightsDetailsSuccess: true,
    athenInsightsData: null,
    athenInsightsDataShort: null,
    newPerformanceData:   {},
    isNewLaneDetailLoad: false,
    newLaneDetailseData: {},
    isNewTemperatureLoad: false,
    newTemperatureData:  {},
    isNewStabilityLoad: false,
    athenaInsightsLoading: false,
    athenaInsightsLongLoading: false,
    newStabilityData: {},
    isNewWaypointOrSegmentLoad: false,
    newWaypointOrSegmentData:   {},
    isNewRouteLoad: false,
    newRouteData:  {},
    newRouteListPopupData : {},
    newRouteListPotentialData : {},
    lanesCount:{},
    isNewShipmentTimeLoad: false,
    newShipmentTimData: {},
    isNewComparisonLoad: false,
    newComparisonData: {},
    newEventStabilityRoutesData: [],
    newEventStabilityLanesData: [],
    newEventStabilityLegsData: [],
    isNewEventCountLoad: false,
    newEventCountData: {},
    isNewEventStabilityLoad: false,
    isDevicePerforrmanceLoad: false,
    newDevicePerformanceData: {},
    co2NewEmissionsLoading: false,
    co2NewEmissions: {},
    co2NewEmissionsError: false,
    isShipmentDetailsLoading:false, 
    shipmentDetailsRoutesData:[],
    shipmentDetailsLegsData:[],
    shipmentDetailsLanesData:[],
    shipmentsCounts:{},
    shipmentDetailsMetaData:{},
    athennaUnloadData: false,
    athenaRouteListInsightsDataShort: null,
    athenaRouteListInsightsDataShortArray: [],
    finalShipmentData:[],
    eventRedirectionData: null,
    shipmentDatesLoading: false,
    shipmentDatesError: false,
    shipmentDates: [],
    editLaneLoader: false,
    editLaneMessage: "",
    lanePerformanceLoading: false,
    lanePerformance: null,
    lanePerformanceError: false,
    motWaypointSegmentLoading: false,
    motWaypointSegment: null,
    motWaypointSegmentError: false,
};
let stability = { lane: 0, route: 0, leg: 0,};

let eventInsight = { lane: 0, route: 0, leg: 0,};


const getLanesListStart = state => {
    return updateObject(state, {
        lanesListLoading: true,
        lanesListError: false,
        //laneDetails: {stability: [], routes:[]},
    });
};
const getLanesListSuccess = (state, action) => {
    return updateObject(state, {
        lanesListLoading: false,
        lanesList: action.data.data,
        lanesListMeta: action.data.meta,
        laneFilterValues:{
            transportModes :  action.data.transport_modes,
            originDestinations:    action.data.origin_destinations,
            productNames: action.data.product_names
        },
        lanesCount: action.data.lane_counts
        }
    );
};
const getLanesListFailed = state => {
    return updateObject(state, {
        lanesListLoading: false,
        lanesListError: true,
    });
};


const  clearEventDetail = state => {
    return updateObject(state, {
        isEventDetailLoad: false,
        eventDetailData: [],
    });
}

const  getEventDetailFailed = state => {
    return updateObject(state, {
        isEventDetailLoad: false,
        eventDetailData: [],

    });
};

const getEventDetailStart = state => {
    return updateObject(state, {
        isEventDetailLoad: true,
        eventDetailData: [],
    });
};

const  getEventDetailSuccess = (state, action) => {
    return updateObject(state, {
        isEventDetailLoad: false,
        eventDetailData: [action.data[0]?.data],
    });
};

const  clearEventList = state => {
    return updateObject(state, {
        isEventListLoad: false,
        eventListData: [],
        eventMetaTotalCount: {}
    });
}

const  getEventListFailed = state => {
    return updateObject(state, {
        isEventListLoad: false,
        // laneEventListData:[],
        // laneHighEventListData: [],
        // laneLowEventListData: [],
        // laneMediumEventListData: [],

    });
};

const getEventListStart = state => {
    return updateObject(state, {
        isEventListLoad: true,
        // laneEventListData:[],
        // laneHighEventListData: [],
        // laneLowEventListData: [],
        // laneMediumEventListData: [],
        laneEventMetaTotalCount: 0,
        eventCounts: null,
    });
};

const  getEventListSuccess = (state, action) => {
    return updateObject(state, {
        isEventListLoad: false,
        eventMetaTotalCount: action.data[0].meta,
        laneEventMetaTotalCount: action.data[0].meta.current_page,
        eventCounts:action.data[0].EventCounts,
        allEventCounts: 
         action?.allDetails?.laneListPage  && (action?.allDetails?.risk_level === "") ? 
        action.data[0].EventCounts : state.allEventCounts,
        laneEventListData:   
        action?.allDetails?.laneListPage  && (action?.allDetails?.risk_level === "") 
        ? 
        action.page === 1 
        ?
            [...action.data[0]?.data]
        : 
            [...state.laneEventListData, ...action.data[0]?.data] :  
        [...state.laneEventListData],
        laneHighEventListData: action?.allDetails?.laneListPage && action?.allDetails?.risk_level === "high" 
        ? 

            action.page === 1 
            ?
                [...action.data[0]?.data] 
            : 
                [...state.laneHighEventListData, ...action.data[0]?.data] 
        :  
            [...state.laneHighEventListData],

        laneLowEventListData: action?.allDetails?.laneListPage && 
        action?.allDetails?.risk_level === "low" 
        ? 
            action.page === 1 
            ?
                [...action.data[0]?.data]
            : 
                [...state.laneLowEventListData, ...action.data[0]?.data] 
        :  
            [...state.laneLowEventListData],
        laneMediumEventListData: action?.allDetails?.laneListPage && 
        action?.allDetails?.risk_level === "medium" ? 
            action.page === 1 
            ?
                [...action.data[0]?.data]
            : 
                [...state.laneMediumEventListData, ...action.data[0]?.data] 
        :  
            [...state.laneMediumEventListData],
        eventListData: action.page === 1 ?  [...action.data[0]?.data]  : [...state.eventListData, ...action.data[0]?.data],
    });
};

// ---

const  getNewPerformanceStart = state => {
    return updateObject(state, {
        isNewPerformanceLoad: true,
        newPerformanceData : {}
    });
};


const getNewPerformanceFailed = state => {
    return updateObject(state, {
        isNewPerformanceLoad: false,
        newPerformanceData : {}
    });
};

const getNewPerformanceSuccess = (state, action) => {
    return updateObject(state, {
        isNewPerformanceLoad: false,
        newPerformanceData:   action.data
    });
};


const  getNewLaneDetailsStart = state => {
    return updateObject(state, {
        isNewLaneDetailLoad: true,
    });
};


const getNewLaneDetailsFailed = state => {
    return updateObject(state, {
        isNewLaneDetailLoad: false,
    });
};

const getNewLaneDetailsSuccess = (state, action) => {
    return updateObject(state, {
        isNewLaneDetailLoad: false,
        newLaneDetailseData:   action.data
    });
};

const  getNewTemperatureStart = state => {
    return updateObject(state, {
        isNewTemperatureLoad: true,
        newTemperatureData: {}
    });
};


const getNewTemperatureFailed = state => {
    return updateObject(state, {
        isNewTemperatureLoad: false,
        newTemperatureData: {}
    });
};

const getNewTemperatureSuccess = (state, action) => {
    return updateObject(state, {
        isNewTemperatureLoad: false,
        newTemperatureData:  action.data
    });
};


const  getNewStabilityStart = state => {
    return updateObject(state, {
        isNewStabilityLoad: true,
    });
};


const getNewStabilityFailed = state => {
    return updateObject(state, {
        isNewStabilityLoad: false,
    });
};

const getNewStabilitySuccess = (state, action) => {
    return updateObject(state, {
        isNewStabilityLoad: false,
        newStabilityData:   action.data
    });
};


const  getNewShipmentTimeStart = state => {
    return updateObject(state, {
        isNewShipmentTimeLoad: true,
        newShipmentTimData: {}
    });
};


const getNewShipmentTimeFailed = state => {
    return updateObject(state, {
        isNewShipmentTimeLoad: false,
        newShipmentTimData: {}
    });
};

const getNewShipmentTimeSuccess = (state, action) => {
    return updateObject(state, {
        isNewShipmentTimeLoad: false,
        newShipmentTimData:   action.data
    });
};

const  getNewComparisonStart = state => {
    return updateObject(state, {
        isNewComparisonLoad: true,
        newComparisonData: {}
    });
};


const getNewComparisonFailed = state => {
    return updateObject(state, {
        isNewComparisonLoad: false,
        newComparisonData: {}
    });
};

const getNewComparisonSuccess = (state, action) => {
    return updateObject(state, {
        isNewComparisonLoad: false,
        newComparisonData:   action.data
    });
};




const  getNewRouteStart = state => {
    return updateObject(state, {
        isNewRouteLoad: true,
    });
};


const getNewRouteFailed = state => {
    return updateObject(state, {
        isNewRouteLoad: false,
    });
};


const getNewRouteSuccess = (state, action) => {
    if(action?.flag) {
        if(action?.potentialTab) {
            return updateObject(state, {
                isNewRouteLoad: false,
                newRouteListPotentialData: action.data
            });
        } else {
            return updateObject(state, {
                isNewRouteLoad: false,
                newRouteListPopupData: action.data

            });
        }
    } else {
        return updateObject(state, {
            isNewRouteLoad: false,
            newRouteData:   action.data
        });
    }
   
};

const  getNewEventStabilityStart = state => {
    return updateObject(state, {
        isNewEventStabilityLoad: true,
    });
};
const getNewEventStabilityFailed = state => {
    return updateObject(state, {
        isNewEventStabilityLoad: false,
    });
};
const getNewEventStabilitySuccess = (state, action) => {

    if(action.data.route==='routes'){
        return updateObject(state, {
            isNewEventStabilityLoad: false,
            newEventStabilityRoutesData: action?.data?.data
        });
    }else if(action.data.route==='legs'){
        return updateObject(state, {
            isNewEventStabilityLoad: false,
            newEventStabilityLegsData: action?.data?.data
        });
    }else if(action.data.route==='lanes'){
        return updateObject(state, {
            isNewEventStabilityLoad: false,
            newEventStabilityLanesData: action?.data?.data
        });
    }
};
const  getShipmentDetailsStart = state => {
    return updateObject(state, {
        isShipmentDetailsLoading: true,
    });
};

const getShipmentDetailsFailed = state => {
    return updateObject(state, {
        isShipmentDetailsLoading: false,
    });
};

const clearFinalShipmentData = (state, _) => {
    return updateObject(state, {finalShipmentData:[]})
   
}

const getShipmentDetailsSuccess = (state, action) => {

    if(action.data.mode==='routes'){
        return updateObject(state, {
            isShipmentDetailsLoading: false,
            shipmentDetailsRoutesData: action?.data?.data,
            shipmentsCounts: action?.data?.shipmentsCounts,
            shipmentDetailsMetaData: action?.data?.meta
        });
    }else if(action.data.mode==='legs'){
        return updateObject(state, {
            isShipmentDetailsLoading: false,
            shipmentDetailsLegsData: action?.data?.data,
            shipmentsCounts: action?.data?.shipmentsCounts,
            shipmentDetailsMetaData: action?.data?.meta
        });
    }else if(action.data.mode==='lanes'){
        if(action.data.flagValue) {
            return updateObject(state, {
                finalShipmentData:[...state.finalShipmentData, ...action?.data?.data],
                isShipmentDetailsLoading: false,
                shipmentDetailsLanesData: action?.data?.data,
                shipmentsCounts: action?.data?.shipmentsCounts,
                shipmentDetailsMetaData: action?.data?.meta
            });
        } else {
            return updateObject(state, {
                isShipmentDetailsLoading: false,
                shipmentDetailsLanesData: action?.data?.data,
                shipmentsCounts: action?.data?.shipmentsCounts,
                shipmentDetailsMetaData: action?.data?.meta
            });
        }
      
    }
};


const  getNewEventCountStart = state => {
    return updateObject(state, {
        isNewEventCountLoad: true,
        newEventCountData :{}
    });
};
const getNewEventCountFailed = state => {
    return updateObject(state, {
        isNewEventCountLoad: false,
        newEventCountData : {}
    });
};
const getNewEventCountSuccess = (state, action) => {
    return updateObject(state, {
        isNewEventCountLoad: false,
        newEventCountData: action.data
    });
};

const  getDevicePerformanceStart = state => {
    return updateObject(state, {
        isDevicePerforrmanceLoad: true,
        newDevicePerformanceData : {}
    });
};
const getDevicePerformanceFailed = state => {
    return updateObject(state, {
        isDevicePerforrmanceLoad: false,
        newDevicePerformanceData: {}
    });
};
const getDevicePerformanceSuccess = (state, action) => {
    return updateObject(state, {
        isDevicePerforrmanceLoad: false,
        newDevicePerformanceData: action.data
    });
};


// ---


const getSeverityLevel = (state, action) => {
    return updateObject(state, {
        severityLevel: action.level,
    });
};

const getEventTypeMap = (state, action) => {
    return updateObject(state, {
        eventTypeValue: action.eventTypeValue,
    });
};


const getNewCO2EmissionsStart = state => {
    return updateObject(state, {
        co2NewEmissionsLoading: true,
        co2NewEmissionsError: false,
    });
};
const getNewCO2EmissionsSuccess = (state, action) => {
    const data = {...action.data};
    
    //console.log('============reducer leg insights=====>>>>>>>>>>>>>>');
    return updateObject(state, {
        co2NewEmissionsLoading: false,
        co2NewEmissionsError: false,
        co2NewEmissions: data,
    });
};
const getNewCO2EmissionsFailed = state => {
    return updateObject(state, {
        co2NewEmissionsLoading: false,
        co2NewEmissionsError: true,
    });
};

const getLaneEditStart = state => {
    return updateObject(state, {
        editLaneLoader: true,
        editLaneMessage: "",
    });
};


const getLaneEditSuccess = (state, action) => {
    return updateObject(state, {
        editLaneLoader: false,
        editLaneMessage: "success",
    });
};

const getLaneEditFailed = state => {
    return updateObject(state, {
        editLaneLoader: false,
        editLaneMessage: "error",
    });
};




const getAthenaInsightStart = (state, action) => {
    if(action.format === "long" ) {
        return updateObject(state, {
            athenaInsightsLoading: true,
            athenaInsightsLongLoading: true,
            athenaInsightsDetailsSuccess: true,
            athenInsightsData: null,
            athennaUnloadData : false,
        });
    } else {
        if(action.pageName === 'routeList'){
            return updateObject(state,{
                athenaInsightsLoading: true,
                athenaRouteListInsightsDataShort: null,
                // athennaUnloadData : false,
                // athenaRouteListInsightsDataShortArray: [],
                athenaInsightsDetailsSuccess: true
                })
        }
        return updateObject(state, {
            athenaInsightsLoading: true,
            athennaUnloadData : false,
            athenaInsightsDetailsSuccess: true,
            athenInsightsDataShort: null

        });

    }
}
const getAthenaInsightSuccess = (state, action) => {
    if(action.format === "long" ) {
        return updateObject(state, {
            athennaUnloadData: false,
            athenaInsightsLongLoading: false,
            athenaInsightsLoading: false,
            athenInsightsData: action.data,
            athenaInsightsDetailsSuccess: true,
        });
    } else {
        if(action.pageName === 'routeList'){
            return updateObject(state,{
            athenaInsightsLoading: false,
            athenaRouteListInsightsDataShortArray :  
            [...state.athenaRouteListInsightsDataShortArray,
                {
                    routeId:action.idValue,
                    athennaData: action.data,
                    athennaUnloadData: false,

                }
             ]
            ,
            // athenaRouteListInsightsDataShort: action.data,
            athenaInsightsDetailsSuccess: true
            })
        }
        return updateObject(state, {
            athenaInsightsLoading: false,
            athennaUnloadData: false,
            athenInsightsDataShort: action.data,
            athenaInsightsDetailsSuccess: true,
        });

    }
 
}


const getAthenaInsightErrorHandling = (state, action) => {
    if(action.format === "long" ) {
        return updateObject(state, {
            athenaInsightsLoading: false,
            athenInsightsData: null,
            athenaInsightsLongLoading: false,
            athenaInsightsDetailsSuccess: true,
            athennaUnloadData : true
        });
    } else {
        if(action.pageName === 'routeList'){
            return updateObject(state,{
            athenaInsightsLoading: false,
            athenaRouteListInsightsDataShortArray :  
            [...state.athenaRouteListInsightsDataShortArray,
                {
                    routeId:action.idValue,
                    athennaData: null,
                    athennaUnloadData: true
                }
             ]
            ,
            athenaRouteListInsightsDataShort: null,
            athenaInsightsDetailsSuccess: true
            })
        }
        return updateObject(state, {
            athenaInsightsLoading: false,
            athenInsightsDataShort: null,
            athennaUnloadData: true,
            athenaInsightsDetailsSuccess: true,
        });

    }
 
}



const getAthenaInsightFailed = state => {

        return updateObject(state, {
            athenaInsightsLongLoading: false,
            athenaInsightsLoading: false,
            athenaInsightsDetailsSuccess: false,
            athenInsightsData: null,
            athennaUnloadData: false,
            athenInsightsDataShort:  null,
            athenaRouteListInsightsDataShort: null,
            // athenaRouteListInsightsDataShortArray: []

        });

}

const getEventDetailsRouteData = (state,action) => {
    console.log("actionData", action)
    return updateObject(state, {
        eventRedirectionData: action.data,
    });
}

const getShipmentDatesStart = state => {
	return updateObject(state, {
			shipmentDatesLoading: true,
			shipmentDatesError: false,
	});
};
const getShipmentDatesSuccess = (state, action) => {
	return updateObject(state, {
			shipmentDatesLoading: false,
			shipmentDates: action.data.data,
	});
};
const getShipmentDatesFailed = state => {
	return updateObject(state, {
			shipmentDatesLoading: false,
			shipmentDatesError: true,
	});
};

const getLanePerformanceStart = state => {
	return updateObject(state, {
			lanePerformanceLoading: true,
			lanePerformanceError: false,
	});
};
const getLanePerformanceSuccess = (state, action) => {
	return updateObject(state, {
			lanePerformanceLoading: false,
			lanePerformance: action.data.data,
	});
};
const getLanePerformanceFailed = state => {
	return updateObject(state, {
			lanePerformanceLoading: false,
			lanePerformanceError: true,
	});
};


const getMotWaypointSegmentStart = state => {
	return updateObject(state, {
			motWaypointSegmentLoading: true,
			motWaypointSegmentError: false,
	});
};
const getMotWaypointSegmentSuccess = (state, action) => {
	return updateObject(state, {
			motWaypointSegmentLoading: false,
			motWaypointSegment: action.data.data,
	});
};
const getMotWaypointSegmentFailed = state => {
	return updateObject(state, {
			motWaypointSegmentLoading: false,
			motWaypointSegmentError: true,
	});
};

const riskIQ = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANES_LIST_START:
            return getLanesListStart(state, action);
        case actionTypes.GET_LANES_LIST_SUCCESS:
            return getLanesListSuccess(state, action);
        case actionTypes.GET_LANES_LIST_FAILED:
            return getLanesListFailed(state, action);
        case actionTypes.GET_EVENT_LIST_START:
            return getEventListStart(state, action);
        case actionTypes.GET_EVENT_LIST_SUCCESS:
            return getEventListSuccess(state, action);
        case actionTypes.GET_EVENT_LIST_FAILED:
            return getEventListFailed(state, action);
        case actionTypes.EVENT_LIST_CLEAR:
            return clearEventList(state, action);

        
        case actionTypes.EVENT_DETAILS_START:
            return getEventDetailStart(state, action);
        case actionTypes.EVENT_DETAILS_SUCCESS:
            return getEventDetailSuccess(state, action);
        case actionTypes.EVENT_DETAILS_FAILED:
            return getEventDetailFailed(state, action);
        case actionTypes.EVENT_DETAILS_CLEAR:
            return clearEventDetail(state, action);
        case actionTypes.GET_NEW_PERFORMANCE_START:
            return getNewPerformanceStart(state, action);
        case actionTypes.GET_NEW_PERFORMANCE_SUCCESS:
            return getNewPerformanceSuccess(state, action);
        case actionTypes.GET_NEW_PERFORMANCE_FAILED:
            return getNewPerformanceFailed(state, action);  
        case actionTypes.GET_NEW_LANE_DETAILS_START:
            return getNewLaneDetailsStart(state, action);
        case actionTypes.GET_NEW_LANE_DETAILS_SUCCESS:
            return getNewLaneDetailsSuccess(state, action);
        case actionTypes.GET_NEW_LANE_DETAILS_FAILED:
            return getNewLaneDetailsFailed(state, action);  
        case actionTypes.GET_NEW_TEMPERATURE_START:
            return getNewTemperatureStart(state, action);
        case actionTypes.GET_NEW_TEMPERATURE_SUCCESS:
            return getNewTemperatureSuccess(state, action);
        case actionTypes.GET_NEW_TEMPERATURE_FAILED:
            return getNewTemperatureFailed(state, action);  
        case actionTypes.GET_NEW_STABILITY_START:
            return getNewStabilityStart(state, action);
        case actionTypes.GET_NEW_STABILITY_SUCCESS:
            return getNewStabilitySuccess(state, action);
        case actionTypes.GET_NEW_STABILITY_FAILED:
            return getNewStabilityFailed(state, action);
            
        case actionTypes. GET_NEW_SHIPMENT_TIME_START:
            return getNewShipmentTimeStart(state, action);
        case actionTypes. GET_NEW_SHIPMENT_TIME_SUCCESS:
            return getNewShipmentTimeSuccess(state, action);
        case actionTypes. GET_NEW_SHIPMENT_TIME_FAILED:
            return getNewShipmentTimeFailed(state, action);

        case actionTypes.GET_NEW_COMPARISON_START:
            return getNewComparisonStart(state, action);
        case actionTypes.GET_NEW_COMPARISON_SUCCESS:
            return getNewComparisonSuccess(state, action);
        case actionTypes.GET_NEW_COMPARISON_FAILED:
            return getNewComparisonFailed(state, action);  

        case actionTypes.GET_NEW_ROUTE_START:
            return getNewRouteStart(state, action);
        case actionTypes.GET_NEW_ROUTE_SUCCESS:
            return getNewRouteSuccess(state, action);
        case actionTypes.GET_NEW_ROUTE_FAILED:
            return getNewRouteFailed(state, action);  

        case actionTypes.GET_NEW_EVENT_STABILITY_START:
            return getNewEventStabilityStart(state, action);
        case actionTypes.GET_NEW_EVENT_STABILITY_SUCCESS:
            return getNewEventStabilitySuccess(state, action);
        case actionTypes.GET_NEW_EVENT_STABILITY_FAILED:
            return getNewEventStabilityFailed(state, action);
        case actionTypes.GET_NEW_SHIPMENT_DETAILS_START:
            return getShipmentDetailsStart(state, action);
        case actionTypes.GET_NEW_SHIPMENT_DETAILS_FAILED:
            return getShipmentDetailsFailed(state, action);
        case actionTypes.GET_NEW_SHIPMENT_DETAILS_SUCCESS:
            return getShipmentDetailsSuccess(state, action);
        case actionTypes.CLEAR_FINAL_SHIPMENT_DATA:
            return clearFinalShipmentData(state, action);
        case actionTypes.GET_NEW_EVENT_COUNT_START:
            return getNewEventCountStart(state, action);
        case actionTypes.GET_NEW_EVENT_COUNT_SUCCESS:
            return getNewEventCountSuccess(state, action);
        case actionTypes.GET_NEW_EVENT_COUNT_FAILED:
            return getNewEventCountFailed(state, action);

        case actionTypes.GET_NEW_DEVICE_PERFPORMANCE_START:
            return getDevicePerformanceStart(state, action);
        case actionTypes.GET_NEW_DEVICE_PERFPORMANCE_SUCCESS:
            return getDevicePerformanceSuccess(state, action);
        case actionTypes.GET_NEW_DEVICE_PERFPORMANCE_FAILED:
            return getDevicePerformanceFailed(state, action);
    
        case actionTypes.EVENT_LIST_SEVERITY:
            return getSeverityLevel(state, action);

        case actionTypes.EVENT_TYPE_MAP:
            return getEventTypeMap(state, action);

        case actionTypes.GET_SHIPMENT_DATES_START:
            return getShipmentDatesStart(state, action);
        case actionTypes.GET_SHIPMENT_DATES_SUCCESS:
            return getShipmentDatesSuccess(state, action);
        case actionTypes.GET_SHIPMENT_DATES_FAILED:
            return getShipmentDatesFailed(state, action);

        case actionTypes.GET_LANE_PERFORMANCE_START:
            return getLanePerformanceStart(state, action);
        case actionTypes.GET_LANE_PERFORMANCE_SUCCESS:
            return getLanePerformanceSuccess(state, action);
        case actionTypes.GET_LANE_PERFORMANCE_FAILED:
            return getLanePerformanceFailed(state, action);

        case actionTypes.GET_MOT_WAYPOINTS_SEGMENTS:
            return getMotWaypointSegmentStart(state, action);
        case actionTypes.GET_MOT_WAYPOINTS_SEGMENTS_SUCCESS:
            return getMotWaypointSegmentSuccess(state, action);
        case actionTypes.GET_MOT_WAYPOINTS_SEGMENTS_FAILED:
            return getMotWaypointSegmentFailed(state, action);

        case actionTypes.GET_NEW_CO2_EMISSIONS_START:
            return getNewCO2EmissionsStart(state, action);
        case actionTypes.GET_NEW_CO2_EMISSIONS_SUCCESS:
            return getNewCO2EmissionsSuccess(state, action);
        case actionTypes.GET_NEW_CO2_EMISSIONS_FAILED:
            return getNewCO2EmissionsFailed(state, action);

        case actionTypes.GET_EDIT_LANE_START:
            return getLaneEditStart(state, action);
        case actionTypes.GET_EDIT_LANE_SUCCESS:
            return getLaneEditSuccess(state, action);
        case actionTypes.GET_EDIT_LANE_FAILED:
            return getLaneEditFailed(state, action);

        case actionTypes.GET_ATHENA_INSIGHT_START:
            return getAthenaInsightStart(state, action);
        case actionTypes.GET_ATHENA_INSIGHT_SUCCESS:
            return getAthenaInsightSuccess(state, action);
        case actionTypes.GET_ATHENA_INSIGHT_ERROR_HANDLING:
            return getAthenaInsightErrorHandling(state, action);
        case actionTypes.GET_ATHENA_INSIGHT_FAILED:
            return getAthenaInsightFailed(state, action);
        case actionTypes.EVENT_DETAILS_ROUTE_DATA:
            return getEventDetailsRouteData(state, action);
        default:
            return state;
    }
};

export default riskIQ;