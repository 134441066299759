const apis = ()=> {
    return {
        "asset_tracking": {
            "refresh_token" : "users/refresh_token",
            "get_company_device_list" : (a) => `asset-tracking/company/${a}/devices`,
            "get_company_device_statuses" : (a) => `asset-tracking/company/${a}/devices/statuses`,
            "get_device_details" : (a,b,c) => `asset-tracking/company/${a}/device/${b}/messages?startDateTime=${c}`,
            "update_device_details" : (a,b) => `asset-tracking/company/${a}/device/${b}/metadata`,
            "get_company_geofences" : (a) => `asset-tracking/company/${a}/geofences`,
            "update_company_geofences" : (a) => `asset-tracking/company/${a}/geofences`,
            "get_product_types" : (a) => `asset-tracking/company/${a}/product_types`,
            "update_product_type" : (a) => `asset-tracking/company/${a}/product_types`
        },
        "group" : {
            "get_group_list": 'v1/groups',
            "get_group_users_list" : (a) => `v1/users?${a}`,
            "get_companies_by_group" : (a) => `v1/companies?group=${a}&limit=50000`,
            "add_new_group" : `v1/groups`,
            "update_group" : (a) => `v1/groups/${a}`,
            "delete_group" : (a) => `v1/groups/${a}`,
            "view_group" : (a) => `v1/groups/${a}`,
            "make_as_group_admin" : (a) => `v1/groups/${a}/admin`,
            "get_group_list_limit" : `v1/groups/limit`
        },
        "company" : {
            "get_company_list" : `v1/companies`,
            "get_company_users_list": (a) => `v1/users?${a}`,
            "add_new_company" : `v1/companies`,
            "update_company" : (a) => `v1/companies/${a}`,
            "enable_disable_company": (a,b) => `v1/companies/${a}/${b}`,
            "get_devices_by_company" : (a) => `device/index?limit=50000&company=${a}`,
            "get_shipment_count_by_company" : (a) => `dashboard/shipmentStatistics?companyID=${a}`,
            "delete_company" : (a) => `v1/companies/${a}`,
            "get_company_details" : (a) => `v1/companies/${a}`,
            "get_group_list": 'v1/groups?limit=50000',
            "get_group_users_list" : (a) => `v1/users?${a}`,
            "get_companies_by_group" : (a) => `v1/companies?group=${a}&limit=50000`,
            "add_new_group" : `v1/groups`,
            "update_group" : (a) => `v1/groups/${a}`,
            "get_devices_by_group" : (a) => `device/index?limit=50000&group=${a}`,
            "get_shipment_count_by_group" : (a) => `dashboard/shipmentStatistics?groupID=${a}`,
            "delete_group" : (a) => `v1/groups/${a}`,
            "view_group" : (a) => `v1/groups/${a}`,
            "assign_company_to_group" : (a) => `groups/${a}/assign_companies`,
            "remove_company_from_group" : (a) => `v1/groups/${a}/assign_companies`,
            "make_as_group_admin" : (a) => `v1/groups/${a}/admin`,
            "get_company_features" : (a) => `v1/companies/${a}/configure`,
            "update_company_features" : (a) => `v1/companies/${a}/configure`,
            "get_company_product_status_template" : (a) => `product-status-config?company_id=${a}`,
            "update_company_product_status_template" : (a) => `product-status-config${a}`
        },
        "configuration" : {
            "update_profile" : (a) => 'users/updateProfile',
            "get_company_list" : 'v1/companies?limit=50000',
            "get_configuration_profile_list" : (a) => `profiles/api/configuration-profiles?company_id=${a}&size=200`,
            "get_configuration_profile" : (a) => `profiles/api/configuration-profiles/${a}`,
            "save_configuration_profile" : (a) => `profiles/api/configuration-profiles${a}`,
            "delete_configuration_profile" : (a) => `profiles/api/configuration-profiles/${a}`,
            "get_alert_profile_list" : (a) => `alertprofiles?companyID=${a}`,
            "get_alert_profile" : (a) => `alertprofiles/${a}`,
            "save_alert_profile" : `alertprofiles`,
            "delete_alert_profile" : (a) => `alertprofiles/${a}`,
            "get_product_profile_list" : (a) => `profiles/api/product-profiles?company_id=${a}&size=500`,
            "get_product_profile" : (a) => `profiles/api/product-profiles/${a}`,
            "save_product_profile" : (a) => `profiles/api/product-profiles${a}`,
            "delete_product_profile" : (a) => `profiles/api/product-profiles/${a}`,
            "get_product_type_list" : (a) => `profiles/api/product-types?company_id=${a}&size=50000`,
            "save_product_type" : (a) => `profiles/api/product-types${a}`,
            "delete_product_type" : (a) => `profiles/api/product-types/${a}`,
            "get_product_type" : (a) => `profiles/api/product-types/${a}`,
            "get_shipment_profile_list" : (a) => `profiles/api/shipment-profiles?company_id=${a}&size=200`,
            "get_shipment_profile" : (a) => `profiles/api/shipment-profiles/${a}`,
            "save_shipment_profile" : (a) => `profiles/api/shipment-profiles${a}`,
            "delete_shipment_profile" : (a) => `profiles/api/shipment-profiles/${a}`,
            "get_carrier_type_list" : `profiles/api/carriers?size=80000`,
            "save_carrier_type" : (a) => `profiles/api/carriers${a}`,
            "delete_carrier_type" : (a) => `profiles/api/carriers/${a}`,
            "get_carrier_type" : (a) => `profiles/api/carriers/${a}`,
            "get_lane_id_list" : (a) => `profiles/api/lanes?company_id=${a}`,
            "save_lane_id" : (a) => `profiles/api/lanes${a}`,
            "delete_lane_id" : (a) => `profiles/api/lanes/${a}`,
            "get_lane_id" : (a) => `profiles/api/lanes/${a}`,
            "get_package_type_list" : (a) => `profiles/api/package-types?company_id=${a}`,
            "save_package_type" : (a) => `profiles/api/package-types${a}`,
            "delete_package_type" : (a) => `profiles/api/package-types/${a}`,
            "get_package_type" : (a) => `profiles/api/package-types/${a}`,
        },
        "dashboard" : {
            "get_dashboard_details" : `dashboard/index`,
            "get_dashboard_config_list" : `dashboard/configure`,
            "set_dashboard_config" : `dashboard/configure`,
            "set_dashboard_config_order" : `dashboard/configorder`,
            "get_shipment_list" : `shipment/list`,
            "get_dashboard_shipment_list" : `dashboard/main`,
            "get_active_company_list" : (a) => `v1/companies/limit?${a||''}`,
            "get_device_shipment_details" : (a) => `shipments/${a}`,
            "get_carrier_shipment_details" : (a) => `shipment/details/${a}`,
            "get_shipment_log" : (a) => `shipment/log/${a}`,
            "get_shipment_geo_json" : (a) => `shipment/geoJson/${a}`,
            "get_shipment_tracking_map" : (a) => `shipment/tracking/map/${a}`,
            "create_new_shipment" : `shipment/createWithTrackingId`,
            "create_new_device_shipment" : (a) => `shipments${a}`,
            "get_shipment_tracker_data" : (a) => `shipments/trackerdata/${a}`,
            "get_carriers_list" : `carrier/list`,
            "get_notification_list" : (a) => `notification/list?page=${a}`,
            "get_unread_notification_count" : `notification/unreadCount`,
            "set_notification_read" : `notification/markRead`,
            "update_shipment_status" : 'shipment/updateStatus',
            "update_shipment_details" : 'shipment/update',
            "save_shipment_geofence" : 'shipment/saveGeofence',
            "delete_shipment_geofence" : (a) => `shipment/deleteGeofence/${a}`,
            "get_shipment_configuration" : (a) => `shipment/configuration/${a}`,
            "update_shipment_configuration" : (a) => `shipment/configuration/${a}`,
            "get_shipment_reports" : (a) => `shipment/reports/${a}`,
            "create_shipment_report" : `shipment/reports`,
            "delete_shipment_report" : (a) => `shipment/reports/${a}`,
            "assign_device_to_shipment" : (a) => `shipment/${a}/assignDevice`,
        },
        "devices" : {
            "get_device_types" : 'device_type/index',
            "get_devices_list" : 'device/index?limit=50000',
            "save_device" : 'device/save',
            "multi_delete_device" : (a) => `device/inactiveDevices?ids=${a}`,
            "upload_devices" : 'device/upload_devices',
            "update_device_details" : (a) => `device/update/${a}`,
            "return_assign_device" : 'device/returnAssignedDeviceFromCompany',
            "assign_device_to_company" : 'device/assignDeviceToCompany',
        },
        "insights" : {
            "get_shipment_reports" : 'reports/shipment',
            "get_location_countries" : `region/countries`,
            "get_country_states" : (a) => `region/countries/${a}/states`,
            "get_state_cities" : (a,b) => `region/countries/${a}/states/${b}/cities`,
            "get_location_routes" : `region/routes`,
        },
        "login" : {
            "login": 'v1/users/login',
            "update_profile" : (a) => `v1/users/${a}`,
            "does_user_exists" : (a) => `v1/users?email=${a}`,
            "password_create": 'v1/password/link',
            "password_token_verification" : (a) => `v1/password/${a}`,
            "password_reset" : (a) => `v1/password`,
            "check_user_company" : 'users/company',
            "validate_user_code" : 'v1/users/activate',
            "resend_user_code" : 'v1/users/resend_email',
            "resend_password_code" : 'v1/password/resend_email',
            "update_password" : (a) => `v1/users/${a}/password`,
            "get_pages_data" : (a) => `v1/pages/${a}`,
            "accept_terms" : (a) => `v1/users/accept_terms`,
            "get_user_details": (a) => `v1/users/logged-in`,
        },
        "pdf" : {
            "upload_document" : 'pdfparser/api/v1/store/document',
            "get_pdf_list" : (a) => `pdfparser/api/v1/documents?${a}`,
            "get_pdf_details" : (a) => `pdfparser/api/v1/documents/${a}`,
            "get_filters_list" : (a) => `pdfparser/api/v1/filters?${a}`,
            "bulk_upload_documents" : 'pdfparser/api/v1/bulk-uploads',
            "get_bulk_pdfs_list" : (a) => `pdfparser/api/v1/bulk-uploads?${a}`,
            "get_zip_pdfs_list" : (a) => `pdfparser/api/v1/bulk-uploads/${a}`,
            "get_pdf_parser_profile_list" : (a) => `pdfparser/api/v1/parser-profiles?${a}`,
            "get_pdf_parser_profile" : (a) => `pdfparser/api/v1/parser-profiles/${a}`,
            "save_pdf_parser_profile" : (a) => `pdfparser/api/v1/parser-profiles${a}`,
            "delete_pdf_parser_profile" : (a) => `pdfparser/api/v1/parser-profiles/${a}`,
            "get_pdf_product_profile_list" : (a) => `pdfparser/api/v1/product-profiles?${a}`,
            "get_pdf_product_profile" : (a) => `pdfparser/api/v1/product-profiles/${a}`,
            "save_pdf_product_profile" : (a) => `pdfparser/api/v1/product-profiles${a}`,
            "delete_pdf_product_profile" : (a) => `pdfparser/api/v1/product-profiles/${a}`,
            "get_active_ingredient_list" : (a) => `pdfparser/api/v1/active-ingredients?company_id=${a}&size=50000`,
            "save_active_ingredient_list" : (a) => `pdfparser/api/v1/active-ingredients${a}`,
            "delete_active_ingredient_list" : (a) => `pdfparser/api/v1/active-ingredients/${a}`,
            "get_active_ingredient" : (a) => `pdfparser/api/v1/active-ingredients/${a}`,
            "get_strength_list" : (a) => `pdfparser/api/v1/strengths?company_id=${a}&size=50000`,
            "save_strength" : (a) => `pdfparser/api/v1/strengths${a}`,
            "delete_strength" : (a) => `pdfparser/api/v1/strengths/${a}`,
            "get_strength" : (a) => `pdfparser/api/v1/strengths/${a}`,
            "get_pdf_lane_id_list" : (a) => `pdfparser/api/v1/lanes?company_id=${a}`,
            "save_pdf_lane_id" : (a) => `pdfparser/api/v1/lanes${a}`,
            "delete_pdf_lane_id" : (a) => `pdfparser/api/v1/lanes/${a}`,
            "get_pdf_lane_id" : (a) => `pdfparser/api/v1/lanes/${a}`,
            "get_pdf_package_type_list" : (a) => `pdfparser/api/v1/package-types?company_id=${a}`,
            "save_pdf_package_type" : (a) => `pdfparser/api/v1/package-types${a}`,
            "delete_pdf_package_type" : (a) => `pdfparser/api/v1/package-types/${a}`,
            "get_pdf_package_type" : (a) => `pdfparser/api/v1/package-types/${a}`,
            "guest_token": "pdfparser/api/v1/guest-token",
            "auth_end_point": "/api/broadcasting/auth",
        },
        "riskiq" : {
            // "get_InTransit_Shipment": (id) => `riskiq-backend/api/v2/lanes/${id}/in-transit-shipments`,
            // "get_lanesv2": "riskiq-backend/api/v2/lanes",
            "get_lanesv3": "riskiq-backend/api/v3/lanes",
            "edit_lanev3": "riskiq-backend/api/v3/lanes/name",
            // "get_event_listv2": "riskiq-backend/api/v2/events",
            "get_event_listv3": "riskiq-backend/api/v3/events",
            // "get_event_detailv2": (id) => `riskiq-backend/api/v2/events/${id}`,
            "get_event_detailv3": (id) => `riskiq-backend/api/v3/events/${id}`,
            // "get_lane_detailsv2" : (a) => `riskiq-backend/api/v2/lanes/${a}`,
            "get_athenna":  (a,b,c,d,e) => 
                `insight-history?format=${a}&lane_id=${b}&route_id=${c}&start_time=${d}&end_time=${e}`,
            "get_lane_detailsv3" : (a,b,c,d) =>  b & c? 
                `riskiq-backend/api/v3/lanes/${a}?start_time=${b}&end_time=${c}&custom=${d}`
            :
                `riskiq-backend/api/v3/lanes/${a}`,
            "get_new_route_v3": (a,b,c,d) => 
                b & c
                ? 
                    `riskiq-backend/api/v3/routes/${a}?start_time=${b}&end_time=${c}&custom=${d}`
                :
                    `riskiq-backend/api/v3/routes/${a}`,

                "get_new_route_list_v3": (a,b) =>
                    b?

                        `riskiq-backend/api/v3/lanes/${a}/routes?route_status=potential`
                        :
                        `riskiq-backend/api/v3/lanes/${a}/routes`
                        ,


            // new api done  b refer for lanes , routes, leg 

            "get_new_performancev3" : (a,b,c,d,e) => 

                Array.isArray(a) ?
                c&d ?
                a.length === 1 ?
        `riskiq-backend/api/v3/performance?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}`
        :a.length === 2 ?
        
        `riskiq-backend/api/v3/performance?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}` 
        :a.length === 3 ?
       
        `riskiq-backend/api/v3/performance?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}` 
        :a.length === 4 ?
        `riskiq-backend/api/v3/performance?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}` 
        :a.length === 5 ?
        `riskiq-backend/api/v3/performance?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
        :`riskiq-backend/api/v3/performance?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 

        :
        a.length === 1 ?

        `riskiq-backend/api/v3/performance?&leg_ids[0]=${a[0]}`
        :a.length === 2 ?

        `riskiq-backend/api/v3/performance?&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}` 
        :a.length === 3 ?

        `riskiq-backend/api/v3/performance?leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}` 
        :a.length === 4 ?
        
        `riskiq-backend/api/v3/performance?leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}` 
        :a.length === 5 ?
        
        `riskiq-backend/api/v3/performance?leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
        :
            `riskiq-backend/api/v3/performance?leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 

        :
                c & d? 
                `riskiq-backend/api/v3/${b}/${a}/performance?start_time=${c}&end_time=${d}&custom=${e}`:

                `riskiq-backend/api/v3/${b}/${a}/performance`,

            
            "get_new_stabilityv3": (a,b,c,d,e) => 
                    c & d? 
                    `riskiq-backend/api/v3/${b}/${a}/stability?start_time=${c}&end_time=${d}&custom=${e}`
                :
                `riskiq-backend/api/v3/${b}/${a}/stability`,
    
            
            "get_new_temperaturev3" : (a,b,c,d, e) => 
                Array.isArray(a) ?
                    c&d ?

        a.length === 1 ?

                `riskiq-backend/api/v3/temperature-profile?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}`
            :a.length === 2 ?
                `riskiq-backend/api/v3/temperature-profile?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}` 
            :a.length === 3 ?
            
                `riskiq-backend/api/v3/temperature-profile?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}` 
            :a.length === 4 ?
           
                `riskiq-backend/api/v3/temperature-profile?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}` 
            :a.length === 5 ?
           
                `riskiq-backend/api/v3/temperature-profile?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
            :
            `riskiq-backend/api/v3/temperature-profile?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 

            :
            a.length === 1 ?
            `riskiq-backend/api/v3/temperature-profile?leg_ids[0]=${a[0]}`
            :a.length === 2 ?

            `riskiq-backend/api/v3/temperature-profile?&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}` 
            :a.length === 3 ?
           
            `riskiq-backend/api/v3/temperature-profile?leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}` 
            :a.length === 4 ?
            
            `riskiq-backend/api/v3/temperature-profile?leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}` 
            :a.length === 5 ?
            
            `riskiq-backend/api/v3/temperature-profile?leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
            : 
           `riskiq-backend/api/v3/temperature-profile?leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
    
            :
                c & d
                ? 
                    `riskiq-backend/api/v3/${b}/${a}/temperature-profile?start_time=${c}&end_time=${d}&custom=${e}` 
                 :
                    `riskiq-backend/api/v3/${b}/${a}/temperature-profile`,
            
            "get_new_shipment_time_v3" : (a,b,c,d,e) => 
                Array.isArray(a) ?
                        c&d ?
                    a.length === 1 ?

                    `riskiq-backend/api/v3/shipment-time?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}`
                    :a.length === 2 ?
                    
                    `riskiq-backend/api/v3/shipment-time?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}` 
                    :a.length === 3 ?
                    
                    `riskiq-backend/api/v3/shipment-time?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}` 
                    :a.length === 4 ?
                    
                    `riskiq-backend/api/v3/shipment-time?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}` 
                    :a.length === 5 ?
                    
                    `riskiq-backend/api/v3/shipment-time?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
    :
            `riskiq-backend/api/v3/shipment-time?start_time=${c}&end_time=${d}&custom=${e}&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 

                    :

                    a.length === 1 ?
                    `riskiq-backend/api/v3/shipment-time?leg_ids[0]=${a[0]}`
                    :a.length === 2 ?
                    
                    `riskiq-backend/api/v3/shipment-time?&leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}` 
                    :a.length === 3 ?
                    
                    `riskiq-backend/api/v3/shipment-time?leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}` 
                    :a.length === 4 ?
                    
                    `riskiq-backend/api/v3/shipment-time?leg_ids[0]=${a[0]}&leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}` 
                    :a.length === 5 ?
                    
                    `riskiq-backend/api/v3/shipment-time?leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
                    :
                    `riskiq-backend/api/v3/shipment-time?leg_ids[1]=${a[1]}&leg_ids[2]=${a[2]}&leg_ids[3]=${a[3]}&leg_ids[4]=${a[4]}` 
            
                :

                c & d
                ? 
                    `riskiq-backend/api/v3/${b}/${a}/shipment-time?start_time=${c}&end_time=${d}&custom=${e}` 
                    :
                    `riskiq-backend/api/v3/${b}/${a}/shipment-time`,
                    
            "get_new_comparison_v3" : (a,b,c,d) => 
                b & c
                ? 
                    `riskiq-backend/api/v3/legs/${a}/performance-comparision?start_time=${b}&end_time=${c}&custom=${d}` 
                    :
                    `riskiq-backend/api/v3/legs/${a}/performance-comparision`,
            
            "get_event_details_stabilityv3": (route, id, startTime, endTime) => 
                (startTime & endTime) ? 
                `riskiq-backend/api/v3/${route}/${id}/event-stability?start_time=${startTime}&end_time=${endTime}` 
                :`riskiq-backend/api/v3/${route}/${id}/event-stability`,

            "get_shipment_details_v3": (mode, id, startTime, endTime) => 
                (startTime & endTime) ? 
                `riskiq-backend/api/v3/${mode}/${id}/shipments?start_time=${startTime}&end_time=${endTime}` 
                :`riskiq-backend/api/v3/${mode}/${id}/shipments`,

            "get_event_count_v3": (a) => 
                `riskiq-backend/api/v3/lanes/${a}/events-count`,

            "get_new_device_performance_v3": (id, urlType, startTime, endTime,custom) => 
                (startTime & endTime) ? 
                `riskiq-backend/api/v3/${urlType}/${id}/device-performance?start_time=${startTime}&end_time=${endTime}&custom=${custom}` 
                :`riskiq-backend/api/v3/${urlType}/${id}/device-performance`,

            "get_event_count_v3": (a) => 
                `riskiq-backend/api/v3/lanes/${a}/events-count`,

            "get_co2_emissions_v3": (id, urlType, startTime, endTime,custom) => 
                (startTime & endTime) ? 
                `riskiq-backend/api/v3/${urlType}/${id}/co2-emission?start_time=${startTime}&end_time=${endTime}&custom=${custom}` 
                :`riskiq-backend/api/v3/${urlType}/${id}/co2-emission`,
            "shipments_start_date": (a) => `riskiq-backend/api/v3/lanes/${a}/shipment-dates`,
            "lane_performance": (a) => `riskiq-backend/api/v3/lanes/${a}/performance`,
            "mot_waypoint_segement": (a) => `riskiq-backend/api/v3/routes/${a}/time-spent`,
// ------

            //   `riskiq-backend/api/v3/lanes/${a}/temperature-profile?start_time=${b}&end_time=${c}&custom=${d}`,
            // "get_route_event_insights" : (id) =>  `riskiq-backend/api/v2/routes/${id}/event-insights`,
            // "get_leg_event_insightsv2" : (id) =>  `riskiq-backend/api/v2/legs/${id}/event-insights`,
            // "get_legs_event_insights": (id) =>  `riskiq-backend/api/v2/legs/${id}/event-insights`,
            // "get_lanes" : `riskiq-backend/api/v1/lanes`,
            // "get_lane_details" : (a) => `riskiq-backend/api/v1/lanes/${a}`,
            // "get_lane_stability" : (a,b) => `riskiq-backend/api/v1/lanes/${a}/stability${b?`/${b}`:''}`,
            // "get_lane_stabilityv2" : (a,b) => `riskiq-backend/api/v2/lanes/${a}/stability${b?`/${b}`:''}`,
            // "get_lane_insights" : (a) => `riskiq-backend/api/v1/lanes/${a}/insights`,
            // "get_lane_insightsv2" : (a) => `riskiq-backend/api/v2/lanes/${a}/insights`,
            // "get_route_details" : (a) => `riskiq-backend/api/v1/routes/${a}`,
            // "get_route_stability" : (a,b) => `riskiq-backend/api/v1/routes/${a}/stability${b?`/${b}`:''}`,
            // "get_route_stabilityv2" : (a,b) => `riskiq-backend/api/v2/routes/${a}/stability${b?`/${b}`:''}`,
            // "get_route_insights" : (a) => `riskiq-backend/api/v1/routes/${a}/insights`,
            // "get_route_insightsv2" : (a) => `riskiq-backend/api/v2/routes/${a}/insights`,
            // "get_leg_stability" : (a,b) => `riskiq-backend/api/v1/legs/${a}/stability${b?`/${b}`:''}`,
            // "get_leg_stabilityv2" : (a,b) => `riskiq-backend/api/v2/legs/${a}/stability${b?`/${b}`:''}`,

            // "get_leg_insights" : (a) => `riskiq-backend/api/v1/legs/${a}/insights`,
            // "get_leg_insightsv2" : (a) => `riskiq-backend/api/v2/legs/${a}/insights`,
            // "get_origins" : (a,b,c) => `riskiq-backend/api/v1/origins?name=${a}&company_id=${b}&destination=${c}`,
            // "get_origins_without_dest" : (a,b) => `riskiq-backend/api/v1/origins?name=${a}&company_id=${b}`,
            // "get_destinations" : (a,b,c) => `riskiq-backend/api/v1/destinations?name=${a}&company_id=${b}&origin=${c}`,
            // "get_destinations_without_origin" : (a,b) => `riskiq-backend/api/v1/destinations?name=${a}&company_id=${b}`,
            // "get_lane_id" : (a,b,c) => `riskiq-backend/api/v1/lanes/origin-destination/${a}/${b}?company_id=${c}`,
        },
        "users" : {
            "add_user" : 'v1/users',
            "update_user": (a) => `v1/users/${a}`,
            "get_user_details": (a) => `v1/users/${a}`,
            "delete_user" : (a) => `v1/users/${a}`,
            "get_shipment_statistics" : (a) => `dashboard/shipmentStatistics?userID=${a}`,
            "activate_user" : (a) => `users/enable/${a}`,
            "get_user_list" : `v1/users`,
            "enable_disable_user": (a) => `v1/users/${a}/status`,
            "update_user_role": (a) => `v1/users/${a}/role`,
            "make_user_poc": (a) => `v1/users/${a}/poc`,
            "get_users_details" : (a) => `v1/users/${a}`,
            "get_user_list_limit" : `v1/users/limit`,
        },
        "help" : {
            "i_frame_src": '',
            "support_email": 'sensitech.support@carrier.com',
            "api_help_email": "sensitech.support@carrier.com"
        }
    }
};
export default apis();